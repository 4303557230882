<template>

  <div class="storeDetails">
    <Header title="" />
    <div class="content">
      <!-- 视频、图片、3d组件 -->
      <media :headData="headData"></media>
      <StoreInfoIteScItem :item="headData" />

      <HdExplain :headData="headData"></HdExplain>
      <div class="item_right">
        <div class="sales">
          <div class="sales_info">本期共{{contentTotal}}种藏品</div>
          <!-- <div class="sales_info">购买需先在“我的”中完成实名认证</div> -->
        </div>
        <div class="bottom_info"></div>
      </div>
    </div>
    <div class="user-list">
      <Goods :store_list="contentData" />
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, watch, nextTick } from "vue";
import Header from "../../components/Header2.vue";
import Goods from "./components/Goods.vue";
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";
import axios from "../../request/request";
import StoreInfoSeriesItem from "./components/StoreInfoSeriesItem.vue";
import StoreInfoIteScItem from "./components/StoreInfoIteScItem.vue";
import { Dialog } from "vant";
import HdExplain from './components/HdExplain'
import Media from "@/components/Media";
export default {
  props: ["item"],
  components: {
	  Media,
    Header,
    Goods,
    StoreInfoSeriesItem,
    StoreInfoIteScItem,
    HdExplain,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    // beforeRouteEnter((to, from, next) => {
    //   if (!from.fullPath.includes('./store')) {
    //     sessionStorage.setItem('goodsScollTop', 0)
    //   }
    //   next()
    // })
    onBeforeRouteLeave((to, from, next) => {
      // 如果不是到store页面，清除goodsScollTop，否侧设置
       if (!to.fullPath.includes('/store')) {
        sessionStorage.setItem('goodsScollTop', 0)
      } else {
        const scrollTop = document.querySelector('.storeDetails').scrollTop
        window.sessionStorage.setItem("goodsScollTop", scrollTop);
      }
      next()
    })
    let data = reactive({
      mid: "",
      contentData: {},
      headData: {},
      isImg: false,
      isVideo: false,
      is3d: false,
      isRaffle: false,
      isOtherPlay: false,
      userId:"0",
      contentTotal:"0",
      centent_nav_list: {
        pic: "https://mj-1304255817.cos.ap-guangzhou.myqcloud.com/shjc/webapp/gxt.png",
        title: "嵩山少林-观星台",
        sales: "10000",
        price: "39.90",
        source: "全球限量首发",
        localSource: "河南嵩山少林寺景区·独家授权",
        tabChildShow: true,
      },
      centent_user_list: [
        {
          tab: "数字版权",
          data: [
            {
              pic:
                "https://mj-1304255817.cos.ap-guangzhou.myqcloud.com/shjc/webapp/gxt.png",
              title: "嵩山少林寺数字藏品·常住院",
              code: "SQ369#123456789/10000",
              price: "39.90",
              source: "嵩山少林寺",
              tabChildShow: true,
            },
            {
              pic:
                "https://mj-1304255817.cos.ap-guangzhou.myqcloud.com/shjc/webapp/ryb.jpg",
              title: "嵩山少林寺数字藏品·常住院",
              code: "SQ369#123456789/10000",
              price: "39.90",
              source: "嵩山少林寺",
              tabChildShow: true,
            },
            {
              pic:
                "https://mj-1304255817.cos.ap-guangzhou.myqcloud.com/shjc/webapp/ryb.jpg",
              title: "嵩山少林寺数字藏品·常住院",
              code: "SQ369#123456789/10000",
              price: "39.90",
              source: "嵩山少林寺",
              tabChildShow: true,
            },
          ],
        },
      ],
    });
    console.log("--good--" + route.query.mid);
      const userOpenid = JSON.parse(window.sessionStorage.getItem("userOpenid"));
       console.log(userOpenid);
        if(!userOpenid){
          // userId = "0";
        }else if (userOpenid.id === null) {
        //  userId = "0";
      }else{
        data.userId = userOpenid.id;
         console.log("--userOpenid.id--" + userOpenid.id);
       
      }
  

    //数据请求
    const getData = async () => {
     

      // axios
        //.get("app/content/" + route.query.mid)
        axios
        .post("app/content" , {
          cid: route.query.mid,
          userId: data.userId,
        })
        .then(function (res) {
          console.log(res);
          console.log(res.status);
          console.log(res.data.code);

          if (res.status === 200 && res.data.code === 0) {
            data.contentData = res.data.data;
            console.log(data.contentData);
            data.headData = res.data.headData;
            data.contentTotal = res.data.count
          }
          // 跳转指定位置
          const scrollTop = window.sessionStorage.getItem("goodsScollTop") || 0;
          nextTick(() => {
            document.querySelector('.storeDetails').scrollTo(0,scrollTop)
          })
          console.log(data.contentData);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    onMounted(() => {
      getData();
    });
    return {
      ...toRefs(data),
    };
  },

  data() {
    return {
      showAuthFlag: false,
      showCombinFlag: false,
    };
  },
  methods: {

  },
};
</script>

<style lang="less" scoped>
.storeDetails {
  height: 100%;
  background: rgba(33, 30, 32, 1);
  padding-top: 40px;
  overflow: auto;
  box-sizing: border-box;
  position: fixed;
  ::v-deep .v {
    border-radius: 0;
  }
}
.user-list {
  background: rgba(33, 30, 32, 1);
  padding-bottom: 50px;
}
.content {
  border-radius: 10px;
  display: flex;
  //   padding: 10px;
  flex-direction: column;
  ::v-deep .hd {
    // margin-top: 70px;
  }
  .item_right {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    // padding: 20px;
    padding-left: 20px;
    padding-right: 20px;
    background: rgba(33, 30, 32, 1);
    .title {
      color: white;
      font-weight: 500;
      font-size: 14px;
      //margin-top: 10px;
      margin-left: 10px;
    }
    .sales {
      margin-top: 30px;
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      .sales_info {
        color: rgba(166, 166, 166, 1);
        font-size: 12px;
        margin-bottom: 10px;
      }
      .sales_data {
        color: rgba(217, 173, 115, 1);
        font-size: 12px;
        background: rgba(33, 30, 32, 1);
        border-radius: 20px;
        padding: 5px 20px 5px 30px;
        margin-top: 10px;
        margin-left: -20px;
      }
      .sales_source {
        color: rgba(113, 72, 12, 1);
        font-size: 12px;
        background: linear-gradient(
          90deg,
          rgba(217, 173, 115, 1) 0%,
          rgba(235, 200, 155, 1) 100%
        );
        border-radius: 20px;
        padding: 5px 20px 5px 20px;
        margin-top: 10px;
        margin-left: 20px;
      }
    }
    .bottom_info {
      width: 100%;
      border-bottom: 1px solid rgba(131, 131, 131, 1);
      // margin-top: 10px;
    }
  }
}
.item_bottom {
  margin-bottom: 10px;
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100%;
  .bottom {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
    .bottom_left {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      margin-top: 10px;
      margin-right: 20px;
      .bottom_mark {
        font-weight: 500;
        font-size: 20px;
        color: white;
        margin-top: 0px;
        margin-right: 2px;
      }
      .bottom_price {
        font-weight: 500;
        font-size: 28px;
        color: white;
      }
    }
    .bottom_right {
      // width: 99px;
      margin-left: 10px;
      margin-top: 10px;
      background: linear-gradient(
        90deg,
        rgba(143, 105, 59, 1) 0%,
        rgba(217, 173, 115, 1) 100%
      );
      border-radius: 17.5px;
      .bottom_buy {
        line-height: 24px;
        font-size: 14px;
        color: rgba(255, 255, 255, 1);
        display: flex;
        align-items: center;
        // margin-left: -15px;
        text-align: center;
        padding: 5px 20px 5px 20px;
      }
    }
  }
}
</style>
