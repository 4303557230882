<template>
  <div class="item">
    <div class="start_time">
      <div>{{item.openingTime}}</div>
    </div>
    <div class="item1" @click="toStore(item)">
      <div class="title">{{ item.detailsTitle }}</div>
      <div class="sales">
        <!-- <div class="sales_info">限量</div> -->
        <!-- <div class="sales_data">{{ item.sales }}份</div>
        <div class="sales_source">{{ item.source }}</div> -->
        <div class="sales_data">
          {{item.detailsInfo}}
        </div>
      </div>

      <div class="bottom_info">
        <div class="bottom_left">
          <div class="bottom_local_source">了解更多</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, watch } from "vue";
import { useRouter } from "vue-router";
export default {
  props: ["item"],
  setup() {
    const router = useRouter();
    const toStore = (item) => {
      router.push({ path: "./topic", query: { mid: item.topicId } });
    };
    let data = reactive({
      top: 10,
    });

    return {
      toStore,
      ...toRefs(data),
    };
  },

  data() {
    return {
      tabChildShow: this.item.tabChildShow,
    };
  },
};
</script>

<style lang="less" scoped>
.item:not(:first-child) {
}
.start_time {
  width: 130px;
  height: 58px;

  margin: -30px 10px 0 10px;
  color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  background: linear-gradient(
    90deg,
    rgba(143, 105, 59, 1) 0%,
    rgba(214, 170, 113, 1) 100%
  );
  font-size: 12px;
  line-height: 28px;
  text-align: center;
  position: relative;
  top: 10px;
  z-index: 1;
}
.item {
  // position: absolute;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin-top: -40px;
  // margin-top: -10px;
  .item1 {
    margin: -20px 20px 0 10px;
    z-index: 20;
    background: rgba(50, 50, 50, 1);
    border-radius: 10px;
    z-index: 10;

    .title {
      color: white;
      font-weight: 500;
      font-size: 14px;
      margin-top: 10px;
      margin-left: 10px;
      z-index: 10;
    }
    .sales {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;

      .sales_data {
        color: rgba(166, 166, 166, 1);
        font-size: 10px;
        // background: rgba(33, 30, 32, 1);
        border-radius: 20px;
        padding: 5px 20px 5px 30px;
        // margin-top: 0px;
        margin-left: -20px;
      }
      .sales_source {
        color: rgba(113, 72, 12, 1);
        font-size: 12px;
        background: linear-gradient(
          90deg,
          rgba(217, 173, 115, 1) 0%,
          rgba(235, 200, 155, 1) 100%
        );
        border-radius: 20px;
        padding: 5px 20px 5px 20px;
        margin-top: 10px;
        margin-left: 20px;
      }
    }
    .bottom_info {
      display: flex;
      flex-flow: row;
      justify-content: flex-end;
      margin-bottom: 10px;
      .bottom_left {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        margin-right: 10px;
        // margin-top: 10px;

        .bottom_local_source {
          font-size: 10px;
          color: rgba(217, 173, 115, 1);
          display: flex;
          align-items: center;
          margin-left: 5px;
        }
      }
    }
    .bottom_info1 {
      margin-bottom: 10px;
      .bottom_local_source {
        font-size: 10px;
        color: rgba(128, 128, 128, 1);
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-left: 10px;
      }
    }
  }
}
</style>
