<template>
  <div class="store_list">
    <div v-for="(i, index) in store_list" :key="index" class="store_list_item">
      <GoodsItem :item="i" />
    </div>
  </div>
</template>

<script>
import GoodsItem from "./GoodsItem.vue";
export default {
  props: ["store_list"],
  components: {
    GoodsItem,
  },
};
</script>

<style lang='less' scoped>
.store_list {

  display: grid;
  grid-template-rows: repeat(4);
  grid-template-columns: repeat(2, 1fr);
  // grid-row-gap: 20px;
    // margin-left: 10px;
    // margin-right: 10px;
      background: rgba(33, 30, 32, 1);
  .store_list_item {
    margin-top: 10px;
    // padding: 20px;
    //  width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>