<template>
  <div class="item" @click="toStore(item)">
    <div class="top">
      <img :src="item.pic" alt="" />
      <div class="pre_buy"
           v-if="getPayStatus(item.saleTime, item.balanceTotal) == 'selling'">
        <span style="color: rgba(67, 233, 123, 1);">正在销售</span>
      </div>
      <div class="pre_buy"
           
           v-if="getPayStatus(item.saleTime, item.balanceTotal) == 'countdown'">
        <span style="color: rgba(67, 233, 123, 1);">{{ "即将开售 "+hours + ":" + mins + ":" + second }}</span>
      </div>
      <div class="bottom_buy"
           v-if="getPayStatus(item.saleTime, item.balanceTotal) == 'sell_out'">
           <img src="@/assets/soldo_out.png" />
      </div>

      <div class="pre_buy"
           v-else-if="getPayStatus(item.saleTime, item.balanceTotal) == 'pre'">
        <span>{{ monthAndDay(item.saleTime)+" "+hourAndmin(item.saleTime) }}</span>
      </div>

    </div>
    <div class="item_right">
      <div class="title">{{ item.title }}</div>
      <div class="bottom_info">
        <div class="bottom_right">
          <div class="bottom_mark">¥</div>
          <div class="bottom_price">{{ item.price }}</div>
        </div>

        <div class="bottom_left">
          <!-- <div class="bottom_local_source">剩余{{item.stock}}件</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { onMounted, reactive, toRefs, watch,onUnmounted,ref } from "vue";

export default {
  props: ["item"],
  setup(props) {
    const router = useRouter();
    const hours = ref("00");
    const mins = ref("00");
    const second = ref("00");
    let countDownTimer = ref ({})
    const toStore = (item) => {
      window.sessionStorage.setItem("flush", true);
      
      router.push({ path: "./store", query: { mid: item.id } });
    };
     const getIntervalHour = (date1, date2) => {
      if (!date2) {
        return 0;
      }
      date1 = new Date(date1.replace(/-/g, "/"));
      date2 = new Date(date2.replace(/-/g, "/"));
      var diff = date1 - date2;
      if (isNaN(diff)) {
        console.log("无效的时间值");
        return;
      }
      return diff / (1000 * 60 * 60);
    };

    const formatDate = (date) => {
      var seperator1 = "-";
      var seperator2 = ":";
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate =
        date.getFullYear() +
        seperator1 +
        month +
        seperator1 +
        strDate +
        " " +
        date.getHours() +
        seperator2 +
        date.getMinutes() +
        seperator2 +
        date.getSeconds();
      return currentdate;
    };
    const monthAndDay = (date) => {
      date = new Date(date.replace(/-/g, "/"));
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      return month + "月" + strDate + "日";
    };
    const hourAndmin = (date) => {
      date = new Date(date.replace(/-/g, "/"));
      let hours = date.getHours();
      let mins = date.getMinutes();
      if (hours >= 0 && hours <= 9) {
        hours = "0" + hours;
      }
      if (mins >= 0 && mins <= 9) {
        mins = "0" + mins;
      }
      return hours + ":" + mins + "开售";
    };
    const getPayStatus = (saleTime, balanceTotal) => {
      if (balanceTotal == "0") {
        return "sell_out";
      }
      if (getIntervalHour(formatDate(new Date()), saleTime) >= 0) {
        return "selling";
      }
      if (getIntervalHour(formatDate(new Date()), saleTime) < 0 && Math.abs(getIntervalHour(formatDate(new Date()), saleTime)) <= 24) {
        return "countdown";
      }
      if (getIntervalHour(formatDate(new Date()), saleTime) < 0 && Math.abs(getIntervalHour(formatDate(new Date()), saleTime)) > 24) {
        return "pre";
      }
    };
    const countTime = () => {
      if (!props.item.saleTime) {
        return;
      }
      if(countDownTimer.value) {
        clearTimeout(countDownTimer)
      }
      var now = new Date().getTime();
      var end = new Date(props.item.saleTime.replace(/-/g, "/")).getTime();
      var leftTime = end - now;
      var  h, m, s;
      if (leftTime >= 0) {
        h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
        m = Math.floor((leftTime / 1000 / 60) % 60);
        s = Math.floor((leftTime / 1000) % 60);
      }
      if (h >= 0 && h <= 9) {
        h = "0" + h;
      }
      if (m >= 0 && m <= 9) {
        m = "0" + m;
      }
      if (s >= 0 && s <= 9) {
        s = "0" + s;
      }
      hours.value = h;
      mins.value = m;
      second.value = s;
      countDownTimer = setTimeout(countTime, 1000);
    };
         onUnmounted(() => {
      if(countDownTimer.value) {
        clearTimeout(countDownTimer)
      }
    });
    return {
      toStore,
            monthAndDay,
      hourAndmin,
      hours,
      mins,
      second,
      countTime,
      countDownTimer,
        formatDate,
          getIntervalHour,
      getPayStatus,
    };
  },
  data() {
    // console.log(this.item);
    return {
      //  tabChildShow: this.item.tabChildShow,
    };
  },
  mounted() {
    if (this.getPayStatus(this.item.saleTime, this.item.balanceTotal) == "countdown") {
      setTimeout(this.countTime, 1000);
    }
  },
};
</script>

<style lang='less' scoped>
.item:not(:first-child) {
}
.item {
  background: rgba(50, 50, 50, 1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  .top {
    position: relative;
    width: 172px;
    height: 171px;
    img {
      border-radius: 10px 10px 0 0;
      // margin-right: 20px;
      width: 172px;
      height: 171px;
    }
    .bottom_buy {
      z-index: 999;
      top:36px;
      left: 37px;
      position: absolute;
      float: left;
      border-radius: 10px;
      img {
        width: 97px;
        height: 98px;
      }
      }
      .pre_buy {
        z-index: 999;
        position: absolute;
        float: left;
        top:12px;
        left: 16px;
        width: 140px;
        height: 24px;
        display: flex;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 10px;
        span {
          text-align: center;
          margin: auto;
          line-height: 24px;
          color: rgba(255, 255, 255, 1);
          font-size: 12px;
        }
      }
  }
  .item_right {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    .title {
      color: white;
      font-weight: 500;
      font-size: 13px;
      margin-top: 10px;
      margin-left: 10px;
    }
    .sales {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      .sales_info {
        color: rgba(113, 72, 12, 1);
        font-size: 6px;
        background: linear-gradient(
          90deg,
          rgba(217, 173, 115, 1) 0%,
          rgba(235, 200, 155, 1) 100%
        );
        border-radius: 10.5px;
        padding: 3px;
        margin-top: 10px;
        margin-left: 10px;
        z-index: 10;
      }
    }
    .bottom_info {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      margin-bottom: 10px;
      .bottom_left {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        margin-left: 10px;
        margin-top: 10px;
        .bottom_image {
          .image {
            height: 15px;
            width: 12px;
          }
        }
        .bottom_local_source {
          font-size: 10px;

          display: flex;
          align-items: center;
          color: rgba(166, 166, 166, 1);
          font-size: 12px;
          margin-right: 10px;
        }
      }
      .bottom_right {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        margin-top: 10px;
        margin-left: 10px;
        .bottom_mark {
          font-weight: 500;
          font-size: 16px;
          color: white;
          margin-top: 6px;
          margin-right: 6px;
        }
        .bottom_price {
          font-weight: 500;
          font-size: 21px;
          color: white;
        }
      }
    }
    .bottom_info1 {
      margin-bottom: 10px;
      .bottom_local_source {
        font-size: 10px;
        color: rgba(128, 128, 128, 1);
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-left: 10px;
      }
    }
  }
}
</style>